import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { cpBrandName, cpBrandNamePlural } from 'Client/constants/brand';
import { Switch } from 'Molecules';
import { Link } from 'Atoms';
import { ConsentsProps } from './types';
import { Section } from './Consents.styles';

export const Consents = ({
  teamPageUrl,
  tracking,
  setTracking,
  communications,
  setCommunications,
  help,
  setHelp,
  settings,
  setSettings,
  trackOnChange,
}: ConsentsProps) => {
  const { t } = useTranslation();
  const cookieSectionProps = {
    teamPageUrl,
    tracking,
    setTracking,
    communications,
    setCommunications,
    help,
    setHelp,
    settings,
    setSettings,
    trackOnChange,
  };

  const TrackingSection = ({
    teamPageUrl,
    tracking,
    setTracking,
    trackOnChange,
  }: Partial<ConsentsProps>) => {
    const trackingSwitchProps = {
      label: t('Help improve your experience'),
      checked: tracking,
      onChange: () => {
        trackOnChange('tracking', tracking);
        setTracking((prevState) => !prevState);
      },
    };
    const list = [
      t('how you got to the site'),
      t('the pages you visit on {{cpBrandName}}', { cpBrandName }),
      t('what you click on'),
    ];
    return (
      <Section>
        <h2>{t('Cookies that help us improve your experience')}</h2>
        <p>
          {t(
            '{{cpBrandName}} uses {{service}} session replay cookies to capture anonymously how you use and interact with {{cpBrandName}} to find ways to improve the site and your user experience. For example,  if there is a stage where people get stuck, we can review the design to give everyone the best experience possible.',
            { cpBrandName, service: 'Logrocket' }
          )}
        </p>
        <Trans>
          <p>
            Additionally, Engagement Teams listed{' '}
            <Link to={teamPageUrl} newTab target="_blank">
              here
            </Link>{' '}
            may choose to implement website measurement cookies, such as Google
            Analytics, Heap or Funnel on this {cpBrandName}. They use these
            cookies to measure reach and effectiveness of this
            {cpBrandName}..
          </p>
        </Trans>
        <p>
          {t(
            'These website measurement tools set cookies that store anonymised information about:'
          )}
        </p>
        {list?.length && (
          <ul>
            {list.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        )}

        <p>
          {t(
            'In all cases, when measuring the website use of respondent users, the data is fully anonymised and only reviewed in an aggregated format.'
          )}
        </p>
        <p>
          {t(
            'Note: {{cpBrandName}} additionally uses {{service}} cookies, which are widely known as a website measurement tool. In the case of {{cpBrandName}}, {{service}} cookies are used to detect and act on possible website abuse or use which does not comply with our Acceptable Use Policy and, for this reason, we deem it to be strictly necessary (see below).',
            {
              cpBrandName,
              service: 'Mixpanel',
            }
          )}
        </p>
        <Switch colorMapping="CommonplaceBrand" {...trackingSwitchProps} />
      </Section>
    );
  };
  const CommunicationSection = ({
    communications,
    setCommunications,
    trackOnChange,
  }: Partial<ConsentsProps>) => {
    const communicationsSwitchProps = {
      label: t('Help with communications and marketing'),
      checked: communications,
      onChange: () => {
        trackOnChange('communications', communications);
        setCommunications((prevState) => !prevState);
      },
    };
    return (
      <Section>
        <h2>{t('Cookies that help with our communications and marketing')}</h2>
        <p>
          {t(
            'In order to maximise the public’s opportunity to comment on local issues, we need as many people as possible to hear about {{cpBrandNamePlural}} in the local area. To help with this outreach, we work with social media platforms such as {{service1}} and {{service2}}, who may show you customised adverts for a {{cpBrandName}} that is relevant to you based on whether you have already contributed.',
            {
              cpBrandName,
              cpBrandNamePlural,
              service1: 'Facebook',
              service2: 'Instagram',
            }
          )}
        </p>
        <Switch
          colorMapping="CommonplaceBrand"
          {...communicationsSwitchProps}
        />
      </Section>
    );
  };
  const HelpSection = ({
    help,
    setHelp,
    trackOnChange,
  }: Partial<ConsentsProps>) => {
    const helpSwitchProps = {
      label: t('Access instant help'),
      checked: help,
      onChange: () => {
        trackOnChange('help', help);
        setHelp((prevState) => !prevState);
      },
    };

    return (
      <Section>
        <h2>{t('Cookies to provide help and communication')}</h2>
        <p>
          {t(
            '{{cpBrandName}} use third party services such as {{service1}} and {{service2}} for you to receive help from {{cpBrandName}} support or to ask questions to the engagement team.',
            { cpBrandName, service1: 'Helpscout', service2: 'Hubspot' }
          )}
        </p>
        <Switch colorMapping="CommonplaceBrand" {...helpSwitchProps} />
      </Section>
    );
  };
  const SettingsSection = ({
    settings,
    setSettings,
    trackOnChange,
  }: Partial<ConsentsProps>) => {
    const settingsSwitchProps = {
      label: t('Remember my settings'),
      checked: settings,
      onChange: () => {
        trackOnChange('settings', settings);
        setSettings((prevState) => !prevState);
      },
    };
    return (
      <Section>
        <h2>{t('Cookies that remember your settings')}</h2>
        <p>
          {t(
            'These cookies remember your preferences and the choices you make, such as whether you like to view {{cpBrandNamePlural}} with high contrast colours or what language you prefer to view multi-lingual {{cpBrandNamePlural}} in.',
            { cpBrandNamePlural }
          )}
        </p>
        <Switch colorMapping="CommonplaceBrand" {...settingsSwitchProps} />
      </Section>
    );
  };
  return (
    <>
      <TrackingSection {...cookieSectionProps} />
      <CommunicationSection {...cookieSectionProps} />
      <HelpSection {...cookieSectionProps} />
      <SettingsSection {...cookieSectionProps} />
    </>
  );
};
