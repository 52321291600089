import styled from 'styled-components';
import { darken } from 'polished/lib';

export const Section = styled.div`
  margin: 2rem 0;

  h2,
  p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }

  h2 + p {
    margin-top: 0;
  }

  ul {
    padding-left: 1rem;
    & > li {
      margin-bottom: 0.5rem;
      font-size: 1rem;
    }
  }

  .MuiFormControlLabel-root:hover {
    .MuiSwitch-thumb {
      background-color: ${({ theme }) =>
        darken(0.1, theme.colorMappings.CommonplaceBrand)};
    }
  }
`;
