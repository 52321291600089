import * as React from 'react';
import { addYears } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';
import { HubPagesTemplate as Template } from 'Templates';
import { getWindowDomain, makeRedirectDomain } from 'Client/utils/url';
import {
  MixpanelEventTypes,
  useAnalytics,
  useProject,
} from 'Client/utils/hooks';
import { Link, LoadingButtonStates } from 'Atoms';
import { Message } from 'Atoms/Message';
import { PRIVACY_POLICY_URL } from 'Client/constants/urls';
import { commonplaceCookies, COOKIES } from 'Client/constants/cookies';
import { getMainDomain } from 'Client/utils/url/getWindowDomain';
import { MESSAGE_TYPES } from 'Atoms/Message/types';
import { cpBrandName, cpBrandNamePlural } from 'Client/constants/brand';
import { SharingButtonRoutes } from 'Client/components/organisms/FloatingShareButton/types';
import { Consents } from './components';
import { ConsentContainer, SaveButton } from './CookiesPage.styles';

export const CookiesPage: React.FC = () => {
  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();
  const [cookies, setCookies, removeCookie] = useCookies();
  const router = useRouter();
  const project = useProject();
  const [loadingState, setLoadingState] = React.useState(
    LoadingButtonStates.INITIAL
  );
  const [tracking, setTracking] = React.useState(false);
  const [communications, setCommunications] = React.useState(false);
  const [help, setHelp] = React.useState(false);
  const [settings, setSettings] = React.useState(false);

  const teamPageUrl = `http://${makeRedirectDomain(project.id)}${
    SharingButtonRoutes.TEAM
  }`;

  const clearUserCookies = () => {
    const allCookies = Object.keys(cookies) as Array<COOKIES>;
    allCookies.forEach((name) => {
      if (!commonplaceCookies.includes(name)) {
        removeCookie(name, { domain: getMainDomain(), path: '/' });
      }
    });
  };

  const handleSavePreferences = () => {
    setLoadingState(LoadingButtonStates.LOADING);
    const preferencesCookie = { tracking, communications, help, settings };
    const cookieOptions = {
      expires: addYears(new Date(), 1),
      domain: getWindowDomain(window.location.host),
      path: '/',
    };
    trackEvent(MixpanelEventTypes.SAVE_COOKIE_PREFERENCES, {
      cookies: preferencesCookie,
    });
    setCookies(
      COOKIES.COMMONPLACE_COOKIE_PREFERENCES,
      JSON.stringify(preferencesCookie),
      cookieOptions
    );
    clearUserCookies();
    setTimeout(() => {
      // this may or may not run depending on users' internet connection,
      // but it's better than always showing the finished state when opening the page
      setLoadingState(LoadingButtonStates.FINISHED);
    }, 1600);
    router.reload();
  };

  const trackOnChange = (cookieType: string, isSelected?: boolean) => {
    trackEvent(
      isSelected
        ? MixpanelEventTypes.OPTED_OUT_COOKIE_TYPE
        : MixpanelEventTypes.OPTED_INTO_COOKIE_TYPE,
      {
        cookieType,
      }
    );
  };

  React.useEffect(() => {
    const preferencesCookie = cookies[COOKIES.COMMONPLACE_COOKIE_PREFERENCES];
    if (preferencesCookie) {
      setTracking(preferencesCookie?.tracking);
      setCommunications(preferencesCookie?.communications);
      setHelp(preferencesCookie?.help);
      setSettings(preferencesCookie?.settings);
    }
  }, []);

  const cookieConsentsProps = {
    teamPageUrl,
    tracking,
    setTracking,
    communications,
    setCommunications,
    help,
    setHelp,
    settings,
    setSettings,
    trackOnChange,
  };

  return (
    <Template>
      <ConsentContainer>
        <h1>
          <img
            width="37"
            height="37"
            src="https://res.cloudinary.com/commonplace-digital-limited/image/upload/w_37,h_37,c_fill/v1647963705/cookie_dvntdc.png"
            alt="Edible Cookie"
          />
          {t('Cookie settings')}
        </h1>
        <Trans>
          <p>
            Cookies are files saved on your device when you visit a website. We
            (both {cpBrandName} and customer Engagement Teams listed{' '}
            <Link to={teamPageUrl} newTab target="_blank">
              here
            </Link>{' '}
            ) use cookies to store information about how you use this
            {cpBrandName}, such as the pages you visit. Choose which cookies you
            are happy for us to use.
          </p>
        </Trans>
        <Consents {...cookieConsentsProps} />
        <Message role={MESSAGE_TYPES.INFO}>
          <h2>{t('Strictly necessary cookies for safety and convenience')}</h2>
          <p>
            {t(
              'These essential cookies do things like remember when you are logged in so that we do not keep on prompting you for your email address every time you contribute. In addition, we use these cookies to detect and act on possible abuse of {{cpBrandNamePlural}} or use which does not comply with our Acceptable Use Policy. They always need to be on.',
              {
                cpBrandNamePlural,
              }
            )}
          </p>
          <Link to={PRIVACY_POLICY_URL} newTab external noPadding>
            {t('Find out more about cookies on {{cpBrandName}}', {
              cpBrandName,
            })}
          </Link>
        </Message>
        <SaveButton
          onClick={handleSavePreferences}
          colorMapping="CommonplaceBrand"
          state={loadingState}
          resetState={() => setLoadingState(LoadingButtonStates.INITIAL)}
        >
          {t('Save preferences')}
        </SaveButton>
      </ConsentContainer>
    </Template>
  );
};
