import * as React from 'react';
import styled, { css } from 'styled-components';
import { Container, LoadingButton } from 'Atoms';

export const SaveButton = styled((props) => <LoadingButton {...props} />)`
  margin-bottom: 4rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      width: 100%;
    }
  `};
`;

export const ConsentContainer = styled(Container)`
  h1 {
    display: flex;
    img {
      margin-right: 0.5rem;
    }
  }
  p {
    font-size: 1rem;
  }
  a {
    padding: 0;
  }
`;
