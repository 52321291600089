import styled, { css } from 'styled-components';
import { MessageProps, MESSAGE_TYPES } from './types';

const getRoleBg = (role: MESSAGE_TYPES) => {
  switch (role) {
    case MESSAGE_TYPES.INFO:
      return '#DEE3F1';
  }
};

const getRoleBorder = (role: MESSAGE_TYPES) => {
  switch (role) {
    case MESSAGE_TYPES.INFO:
      return '#7985A8';
  }
};

export const Message = styled.div<MessageProps>`
  padding: 1rem;
  margin: 1rem 0;

  *:first-child {
    margin-top: 0;
  }

  ${({ theme, role }) => css`
    background-color: ${getRoleBg(role)};
    border-left: 0.25rem solid ${getRoleBorder(role)};

    a {
      text-decoration: underline;
      color: ${theme.colorMappings.plainText};
    }
  `};
`;
